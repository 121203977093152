import React from "react";
import { graphql } from "gatsby";

import ContactConnect from "../../components/owner-since/contact-connect";
import Layout from "../../components/shared/layout-light";

export const query = graphql`
  query {
    jaclyn: file(relativePath: { eq: "owner-since/customer-jaclyn-j.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1280, maxHeight: 1600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default function ContactConnectPage({ data }) {
  return (
    <Layout homeUrl="/owner-since">
      <ContactConnect
        contactType="Customer"
        imageData={data.jaclyn.childImageSharp.fluid}
        formIntro="With a few details, we'll be able to connect you with a real Outer Customer"
        quote="HANDS DOWN this sofa is the nicest piece of outdoor furniture I've ever owned!"
        quoteSource="Jaclyn J."
      />
    </Layout>
  );
}
